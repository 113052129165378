<template>
  <div class="mb-4">
    <button class="btn btn-info mr-2" @click="pickFile">
      Selecionar Arquivos
    </button>
    <button class="btn btn-danger" @click="clearAll" v-if="files.length">
      Limpar tudo
    </button>

    <input
      type="file"
      id="files"
      ref="file"
      accept="image/*"
      style="display: none"
      @change="onFilePicked"
      multiple
    />
  </div>
</template>

<script>
export default {
  name: "ButtonUploadImages",

  data() {
    return {
      files: []
    };
  },

  methods: {
    pickFile() {
      this.$refs.file.click();
    },

    onFilePicked(event) {
      const { files } = event.target;

      Object.keys(files).forEach(key => {
        files[key].url = URL.createObjectURL(files[key]);

        this.files.push(files[key]);
      });

      this.$emit("changeImages", this.files);
    },

    clearAll() {
      this.$refs.file.value = null;
      this.files = [];
      this.$emit("changeImages", []);
    }
  }
};
</script>
